<template>
  <li class="p-[16px]">
    <div
      v-if="item.title.endsWith('akses-akun')"
      class="border-[2px] border-[#F7FFF9] shadow-[0_0_1px_0_rgba(0,0,0,.15)] bg-[#D6EEDD] rounded-[8px] p-[10px] flex flex-col gap-[10px]"
    >
      <span class="text-[10px] text-[#626262]">Butuh Talent Handal</span>
      <strong class="whitespace-normal text-[10px] font-medium text-[#333333]">🎁 Temukan Talent Terbaik di <span class="text-[10px] font-bold text-[#34A853]">Komtim</span>!</strong>
      <a
        href="https://komtim.id/talent-pool"
        target="_blank"
        rel="noopener noreferrer"
        class="h-[28px] !flex justify-center items-center bg-[#34A853] hover:!bg-[#249542] active:!bg-[#0A8341] transition-colors duration-300 text-white px-[12px] font-semibold text-[12px] rounded-[4px] w-fit"
      >
        Coba Sekarang!
      </a>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>
