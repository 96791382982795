<template>
  <b-row>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end pr-3"
          >
            <div class="d-sm-flex d-none user-nav">
              <div class="d-flex">
                <div
                  v-for="item in levels"
                  :key="item.product"
                  class="mr-[5px]"
                >
                  <img
                    :id="String(item.product)"
                    class="icon-level"
                    :src="item.level_img_url"
                  >
                  <b-popover
                    :target="String(item.product)"
                    triggers="hover"
                    placement="bottomleft"
                  >
                    <span class="text-black">
                      {{ item.message }}
                    </span>
                  </b-popover>
                </div>
                <h4 class="user-name align-items-center d-flex font-weight-bolder mb-0 text-black">
                  {{ $store.state.auth.userData.full_name || $store.state.auth.userData.username }}
                </h4>
              </div>
              <h4 class="user-status align-items-center text-black">
                {{ $store.state.auth.userData.role }}
              </h4>
            </div>
          </b-col>
        </b-row>
        <b-avatar
          size="40"
          :src="$store.state.auth.userData.photo_profile_url"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!$store.state.auth.userData.photo_profile_url"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="toggleDarkMode"
      >
        <feather-icon
          size="16"
          :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
          class="mr-50"
        />
        <span>{{ isDark ? 'Light' : 'Dark' }} Mode</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <div class="mr-3 ml-50">
      <b-button
        size="sm"
        variant="flat-primary"
        class="btn-icon position-relative"
        @click="getListNotification"
      >
        <b-img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/notification-1.svg" />
        <div
          v-if="notificationTotal > 0"
          class="wrapper-notification-count"
        >
          <span class="text-white font-semibold">{{ notificationTotal }}</span>
        </div>
      </b-button>
      <b-modal
        id="modal-notification"
        ref="modal-notification"
        modal-class="myclass"
        hide-footer
        hide-header
      >
        <b-row class="p-1 justify-content-between align-items-center mb-2 mx-2">
          <div>
            <h4 class="text-black">
              <strong>
                Notifications
              </strong>
            </h4>
          </div>
          <div>
            <b-img
              src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
              class="cursor-pointer"
              @click="$bvModal.hide('modal-notification')"
            />
          </div>
        </b-row>
        <div class="wrapper-content-notification">
          <b-row
            v-for="(item, index) in listNotification"
            :key="index + 1"
            :style="item.is_read === 0 ? 'background: #FFECE9; margin-left: -19px; margin-right: -19px; border-top: 1px solid #C2C2C2;' : 'background: white; margin-left: -19px; margin-right: -19px; border-top: 1px solid #C2C2C2;'"
            class="p-[2rem] cursor-pointer"
            @click="readNotif(item)"
          >
            <b-col cols="2">
              <b-img :src="item.image_path" />
            </b-col>
            <b-col
              cols="10"
              class="d-flex flex-column"
              style="width: 100%;"
            >
              <div
                class="d-flex justify-content-between align-items-center mb-1"
              >
                <span class="text-black font-semibold">
                  {{ item.title }}
                </span>
                <small
                  style="color: #626262;"
                  class="font-semibold"
                >
                  {{ getFormatDate(item.created_at) }}
                </small>
              </div>
              <small
                style="color: #626262;"
                v-html="item.description"
              />
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </div>
  </b-row>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { mapState } from 'vuex'
import store from '@/store'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { isUserLoggedIn, removeLocalStorageLogout } from '@/auth/utils'
import { newAxiosIns } from '@/libs/axios'
import { toast_error } from '@/libs/toastification'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
      notificationTotal: 0,
      listNotification: [],
      moment,
      level: '',
      icon: '',
      message: '',
      isTopLevel: false,

      ExpiredWhatsapp: 0,
      user: this.$store.state.auth.userData,
      levels: [],
    }
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { skin, isDark }
  },
  computed: {
    isAdmin() {
      return isUserLoggedIn() && this.user.role_name?.toUpperCase() === 'ADMIN'
    },
    profileRoute() {
      if (!isUserLoggedIn()) return ''
      if (this.user.role_name?.toUpperCase() === 'SDM') return 'talent-profile'
      if (this.user.role_name?.toUpperCase() === 'PARTNER') return 'partner-profile'
      return ''
    },
    greetingsTime() {
      const date = new Date()
      let dateChange = null
      let str = ''
      if ((date.getUTCHours() - date.getHours()) !== 0) {
        dateChange = date.getHours()
      } else {
        dateChange = (8 + date.getHours())
      }
      if (dateChange < 4) {
        str = 'Selamat Malam,'
      } else if (dateChange < 11) {
        str = 'Selamat Pagi,'
      } else if (dateChange < 16) {
        str = 'Selamat Siang,'
      } else if (dateChange < 20) {
        str = 'Selamat Sore,'
      } else {
        str = 'Selamat Malam,'
      }
      return str
    },
    ...mapState(
      'dashboard', ['profile'],
    ),
    ...mapState(
      'whatsapp', ['check_wa', 'dashboard_wa'],
    ),
  },
  mounted() {
    this.getCountNotification()
    // this.getBadge()
    this.getUserLevel()
  },
  methods: {
    logout() {
      this.logoutAuthModule()
      if (this.user.is_komform === 1 && this.user.role_id === 4) {
        this.logoutKomform().then(() => {
          this.logoutAndRedirectToLoginPage()
        })
      } else {
        this.logoutAndRedirectToLoginPage()
      }
    },
    formatPrice(value) {
      let val = 0
      if (value !== undefined) {
        val = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    showNotification() {
      this.$refs['modal-notification'].show()
      this.ExpiredWhatsapp = this.dashboard_wa !== null ? this.dashboard_wa.whatsapp_expired_at : 0
    },
    getCountNotification() {
      this.$http_new.get('komship/api/v1/notifications/total_unread')
        .then(response => {
          const { data } = response.data
          this.notificationTotal = data.total_unread_notification
        })
    },
    getListNotification() {
      this.$http_new.get('komship/api/v1/notifications/list')
        .then(response => {
          const { data } = response.data
          this.listNotification = data
          this.showNotification()
        })
    },
    readNotif(data) {
      this.$http_komship.post(`/v1/notification/read/${data.id}`)
        .then(() => {
          this.getCountNotification()
        })
      if (data.notification_type === 'withdrawal') {
        this.$refs['modal-notification'].hide()
        // this.$router.push({ path: `/keuangan/saldo/rincian/${data.reference_id}` }).catch(() => {})
        window.location.assign(`/keuangan/saldo/rincian/${data.reference_id}`)
      } else if (data.notification_type === 'WhatsappPremium') {
        this.$refs['modal-notification'].hide()
        if (this.ExpiredWhatsapp > 0 && this.$route.name !== 'dashboard-whatsapp') {
          this.$router.push({ name: 'dashboard-whatsapp', params: { modal: 'renew' } })
        } else if (this.ExpiredWhatsapp > 0 && this.$route.name === 'dashboard-whatsapp') {
          this.$bvModal.show('modal-renew-subcription')
        } else if (this.ExpiredWhatsapp === 0 && this.$route.name !== 'opsional-feature') {
          this.$router.push({ name: 'opsional-feature', params: { modal: 'renew' } })
        } else if (this.ExpiredWhatsapp === 0 && this.$route.name === 'opsional-feature') {
          this.$bvModal.show('modal-choose-wa')
        }
      } else if (data.notification_type === 'verificationKTPRejected') {
        this.$router.push('/verification-ktp')
        this.$bvModal.hide('modal-notification')
      } else if (data.notification_type === 'verificationKTPApproved') {
        // doing nothing
      } else if (data.notification_type === 'komcardTransactions') {
        this.$router.push('/komcards')
        this.$bvModal.hide('modal-notification')
      } else {
        this.$refs['modal-notification'].hide()
        window.location.assign(`/ticketing/detail/${data.reference_id}`)
      }
    },
    getFormatDate(value) {
      const pastDate = moment(value)
      const currentDate = moment(new Date())
      const asHours = moment.duration(currentDate.diff(pastDate))
      const hour = Math.round(asHours.asHours())
      const minutes = Math.round(asHours.asMinutes())
      let format = ''
      if (hour === 0) {
        format = `${minutes} menit yang lalu`
      }
      if (hour === 1 || hour > 1) {
        format = `${hour} jam yang lalu`
      }
      if (hour > 3 && hour < 24) {
        format = moment(new Date(value)).format('HH.mm')
      }
      if (hour >= 20 && hour <= 48) {
        format = `Kemarin ${moment(new Date(value)).format('HH.mm')}`
      }
      if (hour > 48) {
        format = `${moment(new Date(value)).format('DD MMMM YYYY HH.mm')}`
      }
      return format
    },
    logoutAndRedirectToLoginPage() {
      store.commit('dashboard/UPDATE_ِِMY_PROFILE', [])

      this.$store.commit('komcards/UPDATE_VIEW_LIST', 'grid')

      removeLocalStorageLogout()

      // Reset ability
      this.$ability.update(initialAbility)
      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    logoutKomform() {
      return new Promise((resolve, reject) => {
        window.addEventListener(
          'message',
          event => {
            if (event.data.type === 'komform-signout-complete') {
              resolve('OK')
            }
            if (event.data.type === 'komform-signout-error') {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('ERROR')
            }
          },
          false,
        )
        const iframe = document.createElement('iframe')
        iframe.src = `${process.env.VUE_APP_BASE_URL_KOMFORM}/auth/signout`
        iframe.width = '0'
        iframe.height = '0'
        iframe.style.display = 'none'
        document.body.appendChild(iframe)
      })
    },
    logoutAuthModule() {
      const iframe = document.createElement('iframe')
      iframe.src = `${process.env.VUE_APP_BASE_AUTH_MODULE}/logout`
      iframe.width = '0'
      iframe.height = '0'
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
    },
    toggleDarkMode() {
      this.skin = this.skin === 'light' ? 'dark' : 'light'
      const iframe = document.querySelector('iframe')
      iframe.contentWindow.postMessage({ type: 'DARK_MODE', value: this.skin }, '*')
    },
    async getUserLevel() {
      const url = '/auth/api/v1/user/levels'
      await newAxiosIns.get(url)
        .then(res => {
          const { data: { data } } = res
          this.levels = data
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal mendapatkan level user' })
        })
    },
  },
}
</script>
<style scoped>
::v-deep .myclass > .modal-dialog {
  position: unset;
  transform: none!important;
}
::v-deep .myclass > .modal-dialog > .modal-content {
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  margin: 7rem 2rem;
}
::v-deep .myclass > .modal-dialog > .modal-content .modal-body {
  padding: 0px!important;
}
.wrapper-content-notification {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
}
.wrapper-notification-count {
  background: #F95031;
  height: 24px;
  min-width: 28px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  position:absolute;
  top: -5px;
  right: -5px;
}
.icon-level {
  width: 32px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
